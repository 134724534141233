import Vue from 'vue'
import App from './App.vue'
import VueGtm from '@gtm-support/vue2-gtm';
//import VueGtag from 'vue-gtag'
import router from './router'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta';

Vue.config.productionTip = false

Vue.use(VueMeta);
// Vue.use(VueGtag, {
//   config: {
//       id: 'G-VPBTE2H76Y',
//       params: {
//           send_page_view: false
//       }
//   }
// }, router)


Vue.use(VueGtm, {
  id: 'GTM-W8GV88',
  queryParams: {
  },
  compatibility: false, 
  nonce: '7S00aPbloS', 
  vueRouter: router, 
});


new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
