<template>
  <div class="main-home">
    <div class="title-animation">
      <div class="bar fill1-m title-mobile">
        <svg id="Component_23_1" data-name="Component 23 – 1" xmlns="http://www.w3.org/2000/svg" width="32.381"
          height="149.148" viewBox="0 0 32.381 149.148">
          <rect id="Rectangle_110" data-name="Rectangle 110" width="18.716" height="149.148"
            transform="translate(13.665)" />
          <path id="Path_857" data-name="Path 857" d="M13.666.11h0V18.083H0Z" transform="translate(0 -0.11)" />
        </svg>
      </div>
      <div class="bar fill1 title-desktop">
        <svg id="Component_1_1" data-name="Component 1 – 1" xmlns="http://www.w3.org/2000/svg" width="49.913"
          height="229.902" viewBox="0 0 49.913 229.902">
          <path id="Path_730" data-name="Path 730" d="M2340.569,1107.452v202.2h28.849v-229.9h-28.849Z"
            transform="translate(-2319.505 -1079.747)" />
          <path id="Path_756" data-name="Path 756" d="M2340.569,1079.747l-21.064,27.705h21.064v-27.705Z"
            transform="translate(-2319.505 -1079.747)" />
        </svg>
      </div>
      <div class="title-desktop">
        <div class="bar fill2">
          <svg id="Component_4_1" data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" width="256.852"
            height="115.799" viewBox="0 0 256.852 115.799">
            <path id="Path_684" data-name="Path 684"
              d="M2413.359,208.747c26.864,0,40.144,23.844,40.144,57.761,0,33.75-13.28,57.929-40.144,57.929s-40.144-24.179-40.144-57.929C2373.215,232.591,2386.5,208.747,2413.359,208.747Zm0,28.041c-7.937,0-10.837,8.4-10.837,29.72,0,21.157,2.9,29.888,10.837,29.888s10.838-8.731,10.838-29.888C2424.2,245.184,2421.3,236.788,2413.359,236.788Z"
              transform="translate(-2373.215 -208.638)" />
            <path id="Path_685" data-name="Path 685"
              d="M2496.089,208.747c26.864,0,40.144,23.844,40.144,57.761,0,33.75-13.28,57.929-40.144,57.929s-40.144-24.179-40.144-57.929C2455.945,232.591,2469.225,208.747,2496.089,208.747Zm0,28.041c-7.937,0-10.838,8.4-10.838,29.72,0,21.157,2.9,29.888,10.838,29.888s10.837-8.731,10.837-29.888C2506.926,245.184,2504.026,236.788,2496.089,236.788Z"
              transform="translate(-2373.215 -208.638)" />
            <path id="Path_686" data-name="Path 686"
              d="M2554.551,208.638c14.562,0,23.871,12.221,23.871,27.744,0,15.359-9.309,27.579-23.871,27.579s-23.871-12.22-23.871-27.579C2530.68,220.859,2539.988,208.638,2554.551,208.638Zm0,16.514c-4.2,0-7.207,3.469-7.207,11.23,0,7.6,3,11.065,7.207,11.065s7.356-3.468,7.356-11.065C2561.907,228.621,2558.754,225.152,2554.551,225.152Zm46.691-14.862h11.86l-53.447,110.151h-11.71Zm5.1,56.809c14.563,0,23.721,12.221,23.721,27.745,0,15.358-9.158,27.578-23.721,27.578-14.713,0-23.871-12.22-23.871-27.578C2582.475,279.32,2591.633,267.1,2606.346,267.1Zm0,16.515c-4.2,0-7.356,3.468-7.356,11.23,0,7.6,3.152,11.064,7.356,11.064s7.356-3.468,7.356-11.064C2613.7,287.082,2610.55,283.614,2606.346,283.614Z"
              transform="translate(-2373.215 -208.638)" />
          </svg>
        </div>
        <div class="bar fill3">
          <svg class="" id="Component_3_1" data-name="Component 3 – 1" xmlns="http://www.w3.org/2000/svg" width="727.48"
            height="111.996" viewBox="0 0 727.48 111.996">
            <path id="Path_688" data-name="Path 688"
              d="M2402.234,381.056v57.593h-28.543v-112H2403l25.49,54.235V326.653h28.39v112h-27.322Z"
              transform="translate(-2373.691 -326.653)" />
            <path id="Path_689" data-name="Path 689"
              d="M2461.306,326.653h38.465c29.917,0,47.623,22.332,47.623,55.914,0,33.75-17.554,56.082-47.47,56.082h-38.618Zm38.465,84.291c11.295,0,18.317-10.578,18.317-28.377,0-18.974-6.411-28.209-18.317-28.209h-9.616v56.586Z"
              transform="translate(-2373.691 -326.653)" />
            <path id="Path_690" data-name="Path 690"
              d="M2551.669,326.653h64.26v27.034h-35.412v14.776h34.344V395.5h-34.344v16.12h35.412v27.033h-64.26Z"
              transform="translate(-2373.691 -326.653)" />
            <path id="Path_691" data-name="Path 691"
              d="M2620.051,326.653h44.112c23.354,0,34.955,17.631,34.955,37.948,0,20.149-11.6,37.444-34.955,37.444H2648.9v36.6h-28.849Zm40.6,27.034H2648.9v21.324h11.753c5.342,0,9.158-4.029,9.158-10.746C2669.811,357.717,2666,353.687,2660.653,353.687Z"
              transform="translate(-2373.691 -326.653)" />
            <path id="Path_692" data-name="Path 692"
              d="M2703.394,326.653h64.26v27.034h-35.412v14.776h34.344V395.5h-34.344v16.12h35.412v27.033h-64.26Z"
              transform="translate(-2373.691 -326.653)" />
            <path id="Path_693" data-name="Path 693"
              d="M2800.471,381.056v57.593h-28.543v-112h29.307l25.49,54.235V326.653h28.391v112h-27.322Z"
              transform="translate(-2373.691 -326.653)" />
            <path id="Path_694" data-name="Path 694"
              d="M2859.239,326.653H2897.7c29.917,0,47.624,22.332,47.624,55.914,0,33.75-17.554,56.082-47.471,56.082h-38.617Zm38.464,84.291c11.3,0,18.316-10.578,18.316-28.377,0-18.974-6.41-28.209-18.316-28.209h-9.616v56.586Z"
              transform="translate(-2373.691 -326.653)" />
            <path id="Path_695" data-name="Path 695"
              d="M2949.448,326.653h64.261v27.034H2978.3v14.776h34.344V395.5H2978.3v16.12h35.412v27.033h-64.261Z"
              transform="translate(-2373.691 -326.653)" />
            <path id="Path_696" data-name="Path 696"
              d="M3046.527,381.056v57.593h-28.543v-112h29.306l25.49,54.235V326.653h28.391v112H3073.85Z"
              transform="translate(-2373.691 -326.653)" />
          </svg>
        </div>
        <div class="bar fill4">
          <svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="677.327"
            height="116.083" viewBox="0 0 677.327 116.083">
            <path id="Path_698" data-name="Path 698"
              d="M2621.826,442.729h50.523c20.454,0,28.543,14.945,28.543,28.545,0,13.936-7.174,23.172-16.943,25.69,10.685,1.847,18.622,13.1,18.622,27.37,0,15.783-8.4,30.391-28.39,30.391h-52.355Zm43.2,42.314c3.969,0,6.564-3.526,6.564-8.4,0-4.533-2.595-8.059-6.564-8.059h-14.347v16.455Zm.763,43.824c4.58,0,7.48-3.694,7.48-8.9,0-5.037-2.9-9.067-7.48-9.067h-15.11v17.966Z"
              transform="translate(-2443.536 -440.883)" />
            <path id="Path_699" data-name="Path 699"
              d="M2706.694,442.729h29.154v71.194c0,9.235,3.815,14.944,12.516,14.944,8.4,0,12.363-5.709,12.363-14.944V442.729h29.154v70.858c0,27.2-16.027,43.153-41.517,43.153-25.643,0-41.67-15.951-41.67-42.985Z"
              transform="translate(-2443.536 -440.883)" />
            <path id="Path_700" data-name="Path 700" d="M2794,442.729h28.848V527.02h31.443v27.7H2794Z"
              transform="translate(-2443.536 -440.883)" />
            <path id="Path_701" data-name="Path 701" d="M2858.57,442.729h28.848V527.02h31.443v27.7H2858.57Z"
              transform="translate(-2443.536 -440.883)" />
            <path id="Path_702" data-name="Path 702"
              d="M2933.971,517.617A33.841,33.841,0,0,0,2959,529.371c5.5,0,9.617-2.687,9.617-6.884,0-4.87-4.58-7.556-12.669-10.411-13.127-4.7-32.359-10.578-32.359-35.6,0-18.3,12.821-35.6,37.854-35.6,13.432,0,25.643,4.533,34.648,12.929l-13.127,23.507c-6.716-6.213-14.958-9.067-21.521-9.067-6.411,0-8.4,3.022-8.4,6.38,0,4.7,4.426,7.053,12.974,10.075,13.127,4.7,31.748,11.418,31.748,34.757,0,22.836-14.653,37.276-38.312,37.276-17.553,0-29.764-5.541-38.922-14.944Z"
              transform="translate(-2443.536 -440.883)" />
            <path id="Path_703" data-name="Path 703"
              d="M3056.235,510.9h-25.643v43.824h-28.849v-112h28.849V483.2h25.643V442.729h28.849v112h-28.849Z"
              transform="translate(-2443.536 -440.883)" />
            <path id="Path_704" data-name="Path 704"
              d="M3090.03,442.729h30.833l-3.816,71.362H3094Zm15.416,79.925c8.4,0,15.417,7.724,15.417,17.128,0,9.066-7.022,16.958-15.417,16.958s-15.416-7.892-15.416-16.958C3090.03,530.378,3097.051,522.654,3105.446,522.654Z"
              transform="translate(-2443.536 -440.883)" />
            <path id="Path_705" data-name="Path 705"
              d="M2483.68,441.275c26.864,0,40.144,23.844,40.144,57.761,0,33.75-13.28,57.93-40.144,57.93s-40.144-24.18-40.144-57.93C2443.536,465.119,2456.815,441.275,2483.68,441.275Zm0,28.041c-7.937,0-10.838,8.4-10.838,29.72,0,21.157,2.9,29.888,10.838,29.888s10.837-8.731,10.837-29.888C2494.517,477.712,2491.617,469.316,2483.68,469.316Z"
              transform="translate(-2443.536 -440.883)" />
            <path id="Path_706" data-name="Path 706"
              d="M2542.142,441.166c14.562,0,23.871,12.221,23.871,27.744,0,15.359-9.309,27.579-23.871,27.579s-23.871-12.22-23.871-27.579C2518.271,453.387,2527.579,441.166,2542.142,441.166Zm0,16.514c-4.2,0-7.207,3.469-7.207,11.23,0,7.6,3,11.065,7.207,11.065s7.356-3.468,7.356-11.065C2549.5,461.149,2546.345,457.68,2542.142,457.68Zm46.691-14.862h11.86l-53.447,110.151h-11.71Zm5.1,56.809c14.563,0,23.721,12.221,23.721,27.745,0,15.358-9.158,27.578-23.721,27.578-14.713,0-23.871-12.22-23.871-27.578C2570.066,511.848,2579.224,499.627,2593.937,499.627Zm0,16.515c-4.2,0-7.357,3.468-7.357,11.23,0,7.6,3.153,11.064,7.357,11.064s7.356-3.468,7.356-11.064C2601.293,519.61,2598.141,516.142,2593.937,516.142Z"
              transform="translate(-2443.536 -440.883)" />
          </svg>
        </div>
      </div>
      <div class="fill-t">
        <div class="bar fill5">
          <svg xmlns="http://www.w3.org/2000/svg" width="70.977" height="228.072" viewBox="0 0 70.977 228.072">
            <path id="Path_697" data-name="Path 697" d="M3126.511,354.358h-21.065v-27.7h70.977v27.7h-20.911V554.725h-29Z"
              transform="translate(-3105.446 -326.653)" />
          </svg>
        </div>
      </div>
      <div>
        <div class="bar fill2-m title-mobile">
          <svg id="Component_16_1" data-name="Component 16 – 1" xmlns="http://www.w3.org/2000/svg" width="262.935"
            height="265.232" viewBox="0 0 262.935 265.232">
            <path id="Path_835" data-name="Path 835"
              d="M79.754.11C97.182.11,105.8,15.578,105.8,37.582c0,21.9-8.615,37.581-26.043,37.581S53.711,59.478,53.711,37.582c0-22,8.615-37.472,26.043-37.472m0,18.192c-5.149,0-7.031,5.447-7.031,19.281,0,13.726,1.882,19.39,7.031,19.39s7.03-5.664,7.03-19.39c0-13.834-1.881-19.281-7.03-19.281"
              transform="translate(-53.711 -0.039)" />
            <path id="Path_836" data-name="Path 836"
              d="M162.483.11c17.428,0,26.043,15.468,26.043,37.472,0,21.9-8.615,37.581-26.043,37.581S136.44,59.478,136.44,37.582c0-22,8.615-37.472,26.043-37.472m0,18.192c-5.149,0-7.031,5.447-7.031,19.281,0,13.726,1.882,19.39,7.031,19.39s7.03-5.664,7.03-19.39c0-13.834-1.881-19.281-7.03-19.281"
              transform="translate(-82.77 -0.039)" />
            <path id="Path_837" data-name="Path 837"
              d="M226.661,0c9.447,0,15.486,7.928,15.486,18,0,9.963-6.039,17.892-15.486,17.892S211.175,27.963,211.175,18c0-10.071,6.039-18,15.486-18m0,10.714c-2.727,0-4.676,2.25-4.676,7.285,0,4.928,1.948,7.178,4.676,7.178s4.772-2.251,4.772-7.178c0-5.036-2.046-7.285-4.772-7.285m30.291-9.642h7.694l-34.674,71.46h-7.6Zm3.311,36.855c9.448,0,15.389,7.928,15.389,18,0,9.963-5.941,17.892-15.389,17.892-9.545,0-15.486-7.928-15.486-17.892,0-10.071,5.941-18,15.486-18m0,10.714c-2.727,0-4.772,2.25-4.772,7.285,0,4.929,2.045,7.178,4.772,7.178s4.772-2.25,4.772-7.178c0-5.036-2.045-7.285-4.772-7.285"
              transform="translate(-109.021)" />
            <path id="Path_838" data-name="Path 838"
              d="M72.7,153.31v37.363H54.187V118.016H73.2L89.736,153.2V118.016h18.418v72.657H90.429Z"
              transform="translate(-53.878 -41.453)" />
            <path id="Path_839" data-name="Path 839"
              d="M141.8,118.016h24.954c19.409,0,30.9,14.488,30.9,36.274,0,21.9-11.388,36.383-30.8,36.383H141.8ZM166.755,172.7c7.328,0,11.883-6.862,11.883-18.41,0-12.309-4.159-18.3-11.883-18.3h-6.238V172.7Z"
              transform="translate(-84.653 -41.454)" />
            <path id="Path_840" data-name="Path 840"
              d="M232.164,118.016h41.689v17.538H250.879v9.587H273.16v17.538H250.879v10.458h22.973v17.538H232.164Z"
              transform="translate(-116.393 -41.453)" />
            <path id="Path_841" data-name="Path 841"
              d="M300.546,118.016h28.618c15.151,0,22.677,11.437,22.677,24.618,0,13.072-7.526,24.292-22.677,24.292h-9.9v23.747H300.546Zm26.34,17.538h-7.625v13.835h7.625c3.466,0,5.941-2.614,5.941-6.972,0-4.248-2.476-6.862-5.941-6.862"
              transform="translate(-140.412 -41.454)" />
            <path id="Path_842" data-name="Path 842"
              d="M383.889,118.016h41.689v17.538H402.6v9.587h22.281v17.538H402.6v10.458h22.973v17.538H383.889Z"
              transform="translate(-169.687 -41.453)" />
            <path id="Path_843" data-name="Path 843"
              d="M73.03,269.534V306.9H54.513V234.24H73.525l16.537,35.185V234.24H108.48V306.9H90.755Z"
              transform="translate(-53.992 -82.277)" />
            <path id="Path_844" data-name="Path 844"
              d="M141.823,234.24h24.954c19.409,0,30.9,14.488,30.9,36.274,0,21.9-11.388,36.383-30.8,36.383H141.823Zm24.954,54.684c7.328,0,11.882-6.862,11.882-18.41,0-12.309-4.158-18.3-11.882-18.3h-6.238v36.71Z"
              transform="translate(-84.66 -82.277)" />
            <path id="Path_845" data-name="Path 845"
              d="M232.033,234.24h41.689v17.538H250.749v9.587h22.28V278.9h-22.28V289.36h22.973V306.9H232.033Z"
              transform="translate(-116.347 -82.277)" />
            <path id="Path_846" data-name="Path 846"
              d="M319.085,269.534V306.9H300.568V234.24h19.013l16.537,35.185V234.24h18.418V306.9H336.811Z"
              transform="translate(-140.42 -82.277)" />
            <path id="Path_847" data-name="Path 847" d="M401.7,252.214H388.031V234.24h46.046v17.974H420.511v94.548H401.7Z"
              transform="translate(-171.142 -82.277)" />
            <path id="Path_848" data-name="Path 848"
              d="M146.362,349.988h16.885c6.836,0,9.54,4.995,9.54,9.54,0,4.658-2.4,7.744-5.663,8.586,3.571.617,6.224,4.377,6.224,9.147,0,5.276-2.806,10.157-9.489,10.157h-17.5ZM160.8,364.129c1.327,0,2.193-1.178,2.193-2.806,0-1.515-.867-2.694-2.193-2.694H156v5.5Zm.255,14.647c1.53,0,2.5-1.235,2.5-2.974,0-1.684-.969-3.031-2.5-3.031H156v6Z"
              transform="translate(-86.255 -122.934)" />
            <path id="Path_849" data-name="Path 849"
              d="M190.082,349.988h9.744v23.794c0,3.086,1.275,4.994,4.183,4.994,2.806,0,4.132-1.908,4.132-4.994V349.988h9.744v23.681c0,9.092-5.356,14.422-13.875,14.422-8.57,0-13.927-5.331-13.927-14.366Z"
              transform="translate(-101.612 -122.934)" />
            <path id="Path_850" data-name="Path 850" d="M235.061,349.988H244.7v28.171h10.508v9.259h-20.15Z"
              transform="translate(-117.411 -122.934)" />
            <path id="Path_851" data-name="Path 851" d="M268.323,349.988h9.642v28.171h10.508v9.259h-20.15Z"
              transform="translate(-129.094 -122.934)" />
            <path id="Path_852" data-name="Path 852"
              d="M304.736,374.682a11.311,11.311,0,0,0,8.366,3.928c1.837,0,3.214-.9,3.214-2.3,0-1.627-1.53-2.525-4.234-3.479-4.387-1.571-10.815-3.535-10.815-11.9,0-6.117,4.285-11.9,12.651-11.9a16.764,16.764,0,0,1,11.58,4.321l-4.387,7.856a11.011,11.011,0,0,0-7.193-3.031c-2.143,0-2.806,1.011-2.806,2.133,0,1.571,1.48,2.357,4.336,3.367,4.387,1.571,10.611,3.816,10.611,11.616,0,7.633-4.9,12.459-12.8,12.459-5.867,0-9.948-1.852-13.009-4.995Z"
              transform="translate(-140.307 -122.6)" />
            <path id="Path_853" data-name="Path 853"
              d="M360.293,372.771h-8.57v14.647h-9.642V349.987h9.642v13.524h8.57V349.987h9.642v37.431h-9.642Z"
              transform="translate(-155.002 -122.934)" />
            <path id="Path_854" data-name="Path 854"
              d="M387.563,349.987h10.3l-1.275,23.85h-7.7Zm5.152,26.712c2.806,0,5.152,2.581,5.152,5.724,0,3.03-2.347,5.668-5.152,5.668s-5.152-2.638-5.152-5.668c0-3.143,2.347-5.724,5.152-5.724"
              transform="translate(-170.977 -122.934)" />
            <path id="Path_855" data-name="Path 855"
              d="M67.93,349.238c8.978,0,13.417,7.969,13.417,19.3,0,11.28-4.439,19.361-13.417,19.361s-13.417-8.081-13.417-19.361c0-11.336,4.438-19.3,13.417-19.3m0,9.372c-2.653,0-3.622,2.806-3.622,9.933,0,7.071.969,9.989,3.622,9.989s3.622-2.918,3.622-9.989c0-7.127-.969-9.933-3.622-9.933"
              transform="translate(-53.992 -122.671)" />
            <path id="Path_856" data-name="Path 856"
              d="M100.991,349.182c4.867,0,7.978,4.085,7.978,9.273,0,5.133-3.111,9.217-7.978,9.217s-7.978-4.085-7.978-9.217c0-5.188,3.111-9.273,7.978-9.273m0,5.52c-1.405,0-2.408,1.159-2.408,3.753,0,2.539,1,3.7,2.408,3.7s2.459-1.159,2.459-3.7c0-2.594-1.054-3.753-2.459-3.753m15.6-4.967h3.965L102.7,386.548H98.784ZM118.3,368.72c4.867,0,7.928,4.085,7.928,9.273,0,5.133-3.061,9.217-7.928,9.217-4.917,0-7.978-4.085-7.978-9.217,0-5.188,3.061-9.273,7.978-9.273m0,5.52c-1.405,0-2.459,1.159-2.459,3.753,0,2.539,1.054,3.7,2.459,3.7s2.459-1.159,2.459-3.7c0-2.594-1.054-3.753-2.459-3.753"
              transform="translate(-67.516 -122.651)" />
          </svg>
        </div>
      </div>
      <div class="dot dot-home"></div>
      <div class="dot-home-animate"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomePage',
  components: {
  },
  mounted() {
    const metaTag = ensureMetaTagExists('og:image', 'https://ignis-vids.s3.eu-west-1.amazonaws.com/ignis.png');
    moveMetaTagToTop(metaTag);

  },
}
function ensureMetaTagExists(property, content) {
  let metaTag = document.querySelector(`meta[property="${property}"]`);
  if (!metaTag) {
    metaTag = document.createElement('meta');
    metaTag.setAttribute('property', property);
    document.head.appendChild(metaTag);
  }
  metaTag.setAttribute('content', content);
  return metaTag;
}

function moveMetaTagToTop(metaTag) {
  const head = document.head;
  // Check if the meta tag is already the first child
  if (head.firstChild !== metaTag) {
    head.insertBefore(metaTag, head.firstChild);
  }
}
</script>

<style lang="scss">
.v-main__wrap {
  position: unset;
}

.wrapper {
  z-index: unset;
}
</style>
