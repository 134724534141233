<template>
  <v-app>
    <AppMenu @menuClose="onMenuClose" @menuOpen="onMenuOpen"></AppMenu>
    <v-main>
      <router-view :class="{ withMenu: menuOpen }" />
    </v-main>
  </v-app>
</template>

<script>
import AppMenu from '@/components/AppMenu';

export default {
  name: 'App',
  components: {
    AppMenu
  },
  methods: {
    onMenuOpen() {
      this.menuOpen = true;
    },
    onMenuClose() {
      this.menuOpen = false;
    },
  },
  data: () => ({
    zoomCircle: false,
    menuOpen: false,
  }),
  mounted() {
    let menuHome = document.querySelectorAll(".menu-home");
    let logo = document.querySelector(".logo");
    let menuContact = document.querySelectorAll(".menu-contact");
    let button = document.querySelector(".button");
    let aboutButton = document.querySelector(".body-about .button");

    logo.addEventListener("click", function (e) {
      e.preventDefault();
      setTimeout(goToHome, 800)
    });
    function goToHome() {
      window.location.replace('/');
    }
    for (let i = 0; i < menuHome.length; i++) {
      menuHome[i].addEventListener("click", function () {
      });
    }
    function goToContact() {
      document.querySelector('.dot-contact').classList.add("scale-dot");
    }
    if (aboutButton) {
      aboutButton.addEventListener("click", function () {
        document.querySelector('.main-about')?.classList.toggle("opacity-about");
        document.querySelector('html').classList.toggle("overflow-about");
        document.querySelector('body').classList.toggle("overflow-about");
      });
    }
    // contact page
    if (!aboutButton) {
      button.addEventListener("click", function () {
        document.querySelector('.main-contact .list')?.classList.toggle("opacity-d");
        document.querySelector('body').classList.toggle("overflow");
        document.querySelector('html').classList.toggle("overflow");
      });
    }

    for (let i = 0; i < menuContact.length; i++) {
      menuContact[i].addEventListener("click", () => {
        document.querySelector('.dot-contact-red').classList.add("dot-scale-red");
        document.querySelector('.dot-contact').classList.add("scale-dot-contact");
        document.querySelector('.navigation').classList.add("opacity");
        setTimeout(scaleDot, 50)
        setTimeout(goToContact, 2000)
      });
    }
    function scaleDot() {
      document.querySelector('.dot-contact').classList.add("scale-dot-contact");
    }
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/style';
</style>
