<template>
  <header class="header">
    <div class="header__nav">
      <a href="javascript://" @click="closeMenu">
        <img
          class="logo"
          loading="lazy"
          src="@/assets/images/logo.svg"
          alt="logo"
        />
      </a>
      <input type="checkbox" id="active" aria-label="menu" />
      <label for="active" @click="toggleMenuOpen" class="button">
        <div class="dot-about-red"></div>
        <div class="dot-about-white"></div>
      </label>
    </div>
    <nav class="navigation" :class="{ checked: navigation }">
      <ul>
        <li class="work menu-home" @click="onWorkClick">
          <router-link to="/work">WORK</router-link>
        </li>
        <li class="about menu-about" @click="onAboutClick">
          <router-link to="/about">ABOUT</router-link>
        </li>
        <li class="contact menu-contact" @click="onContactClick">
          <router-link to="/contact">CONTACT</router-link>
        </li>
      </ul>
    </nav>
    <div class="wrapper" :class="[circleClass]"></div>
  </header>
</template>

<script>
export default {
  name: "AppMenu",
  props: {},
  data: () => ({
    menuState: 0,
    navigation: false,
  }),
  watch: {
    // $route() {
    //   console.log(this.$router.history);
    // },
  },
  methods: {
    goToHome: function () {
      this.$router.push("/");
    },
    toggleMenuOpen() {
        if (this.menuState !== 0) {
            this.menuState = 0;
            this.navigation = false;
          this.$emit("menuClose");
        } else {
          this.$emit("menuOpen");
          if (this.$route.path=='/about') {
            this.menuState = 2;
            this.navigation = true;
          } else {
            this.menuState = 1;
            this.navigation = true;
          }
        }
    },
    closeMenu() {
        this.menuState = 0;
        this.navigation = false;
        this.$emit("menuClose");
    },
    onWorkClick() {
        this.closeMenu();
    },
    onContactClick() {
        this.closeMenu();
    },
    onAboutClick() {
        this.closeMenu();
    },
    goHome() {
      this.closeMenu();
      setTimeout(() => {
        this.$router.push('/')
      }, 50);
    }
  },
  computed: {
    circleClass() {
        switch (this.menuState) {
            case 0:
                return 'zoom-circle-out';
            case 1:
                return 'zoom-circle';
            case 2:
                return 'zoom-circle-about';
            default:
                return '';
        }
    }
  }
};
</script>
